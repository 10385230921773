<template>
  <SidePanel>
    <PanelSection>
      <template #title>Update Agency</template>

      <FormFields :agency-form="agencyForm" :errors="errors" />
    </PanelSection>

    <PanelSection>
      <template #title
        ><div class="flex justify-between">
          Users
          <span v-if="agency"
            ><span class="text-gray-400">Total: </span>
            <span
              :class="{
                'text-gray-700':
                  !agency.userLimit || users.length <= agency.userLimit,
                'text-red-600':
                  agency.userLimit && users.length > agency.userLimit,
              }"
            >
              {{ users.length }} / {{ agency.userLimit ?? "∞" }}</span
            ></span
          >
        </div></template
      >

      <FetchLoader :fetch-status="fetchStatus">
        <div
          class="text-sm max-h-96 border border-gray-200 bg-gray-200 overflow-y-auto gap-px rounded grid grid-cols-3"
          v-if="users.length"
        >
          <div class="px-2 py-1 font-bold bg-gray-100 col-span-2 text-xs">
            Email
          </div>
          <div class="px-2 py-1 font-bold bg-gray-100 text-xs">Last Active</div>

          <template v-if="agency" v-for="user of users" :key="user.id">
            <div class="px-2 py-1 bg-white col-span-2 flex gap-2 items-center">
              <SuperAdminUserLink :email="user.email" />
              <span
                v-if="user.isAgencyAdmin"
                class="text-xs rounded-sm bg-purple-300 text-purple-700 px-1.5"
                >Admin</span
              >
              <span
                v-if="user.status === 'agency_disabled'"
                class="text-xs rounded-sm bg-red-300 text-red-700 px-1.5"
                >Disabled</span
              >
            </div>
            <div class="px-2 py-1 bg-white">
              {{ user.lastActiveAt ? timeAgo(user.lastActiveAt) : "Never" }}
            </div>
          </template>
        </div>
      </FetchLoader>
    </PanelSection>

    <PanelSection>
      <template #title>Add User</template>

      <FormErrors :errors="userErrors" />

      <FormControl :errors="userErrors?.email">
        <template #label>Email</template>
        <div class="flex gap-2 text-sm">
          <input
            class="input is-sm"
            v-model="userForm.email"
            @keypress.enter="createUser"
          />
          <SaveBtn
            :saveStatus="userSaveStatus"
            @save="createUser"
            :disabled="!userForm.email"
            color="secondary"
            size="small"
            label="Add"
          />
        </div>
      </FormControl>
    </PanelSection>

    <PanelSection>
      <template #title>Admin Log</template>

      <FetchLoader :fetch-status="fetchStatus">
        <div
          class="max-h-80 border border-gray-200 text-xs divide-y divide-gray-200 rounded-lg overflow-y-auto"
          v-if="agency?.agencyAdminLogs.length"
        >
          <div
            v-for="log of agencyAdminLogs"
            :key="log.id"
            class="px-2 py-2.5"
            :title="JSON.stringify(log.data)"
          >
            <div class="text-sm mb-1">
              <span
                class="font-mono tracking-tighter bg-gray-300 text-gray-700 rounded px-1 mr-2"
                >{{ log.action }}</span
              >
              <SuperAdminUserLink :email="log.userEmail" />
            </div>
            <div>
              <span class="text-gray-400">by </span>
              <SuperAdminUserLink :email="log.adminEmail" />
              <span class="text-gray-400"> on </span>
              <span class="">{{ niceDateTime(log.createdAt, "UTC") }}</span>
            </div>
          </div>
        </div>
      </FetchLoader>
    </PanelSection>

    <PanelSection>
      <template #title>Billing</template>

      <FetchLoader :fetch-status="fetchStatus">
        <BillingAccount
          v-if="agency"
          :billing-account="agency.billingAccount"
          @update="updateBillingAccount"
        />
      </FetchLoader>
    </PanelSection>

    <PanelSection>
      <template #title>Face Database</template>

      <FetchLoader :fetch-status="fetchStatus">
        <KeyValueDetails v-if="agency?.rekognitionCollection">
          <KeyValue label="Collection Type">{{
            agency.rekognitionCollection.kind
          }}</KeyValue>
          <KeyValue
            v-if="agency.rekognitionCollection.faceEngineUrl"
            label="Face Engine URL"
            >{{ agency.rekognitionCollection.faceEngineUrl }}</KeyValue
          >
          <KeyValue
            v-if="agency.rekognitionCollection.faceStorageUrl"
            label="Face Storage URL"
            >{{ agency.rekognitionCollection.faceStorageUrl }}</KeyValue
          >
          <KeyValue label="Storage Bucket">{{
            agency.rekognitionCollection.s3Bucket
          }}</KeyValue>
          <KeyValue
            v-if="agency.rekognitionCollection.awsProxyUrl"
            label="AWS Proxy URL"
            >{{ agency.rekognitionCollection.awsProxyUrl }}</KeyValue
          >
          <KeyValue label="Collection ID">{{
            agency.rekognitionCollection.id
          }}</KeyValue>
          <KeyValue label="Collection Face Model">{{
            agency.rekognitionCollection.faceModel
          }}</KeyValue>
          <KeyValue label="Collection People Records">{{
            niceNumber(agency.rekognitionCollection.peopleCount)
          }}</KeyValue>
          <KeyValue label="Collection Reference Faces">
            {{
              niceNumber(agency.rekognitionCollection.referenceFaceCount ?? 0)
            }}
          </KeyValue>
          <KeyValue label="Orphaned Faces Removed">
            {{
              niceNumber(
                agency.rekognitionCollection.totalOrphanedFacesRemoved ?? 0
              )
            }}
          </KeyValue>
        </KeyValueDetails>
        <div v-else class="text-red-400">
          No rekognition collection for agency!
        </div>
      </FetchLoader>
    </PanelSection>

    <PanelSection>
      <template #title>All Time Usage Stats</template>

      <FetchLoader :fetch-status="fetchStatus">
        <KeyValueDetails v-if="agency">
          <KeyValue label="People Created">
            {{ niceNumber(agency.allTimePeopleCreated ?? 0) }}
          </KeyValue>

          <KeyValue label="Images Processed">
            {{ niceNumber(agency.allTimeImagesProcessed ?? 0) }}
          </KeyValue>

          <KeyValue label="Text Processed">
            {{ niceNumber(agency.allTimeTextProcessed ?? 0) }}
          </KeyValue>

          <KeyValue label="Faces Processed">
            {{ niceNumber(agency.allTimeFacesProcessed ?? 0) }}
          </KeyValue>

          <KeyValue label="Average faces per image">
            <template v-if="agency.allTimeImagesProcessed">
              {{
                (
                  (agency.allTimeFacesProcessed ?? 0) /
                  agency.allTimeImagesProcessed
                ).toFixed(2)
              }}
            </template>
            <template v-else>-</template>
          </KeyValue>
        </KeyValueDetails>
      </FetchLoader>
    </PanelSection>

    <template #buttons-left>
      <SaveBtn
        :saveStatus="saveStatus"
        @save="update"
        :disabled="!agencyForm.name"
      />
    </template>

    <template #buttons-right>
      <Btn
        @click="emit('close')"
        :disabled="saveStatus === 'saving'"
        color="secondary"
        fill="underline"
        >Cancel</Btn
      >
    </template>
  </SidePanel>
</template>

<script setup lang="ts">
import SidePanel from "../../global/SidePanel.vue";
import SaveBtn from "../../global/SaveBtn.vue";
import Btn from "../../global/Btn.vue";
import PanelSection from "../../global/SidePanel/PanelSection.vue";
import useModelApi from "../../global/useModelApi";
import FormFields from "./FormFields.vue";
import useFetchApi from "../../global/useFetchApi";
import { computed, ref, watch } from "vue";
import FetchLoader from "../../global/FetchLoader.vue";
import {
  niceDateTime,
  orderedByTimeField,
  timeAgo,
} from "../../../utility/time";
import KeyValueDetails from "../../global/KeyValueDetails.vue";
import KeyValue from "../../global/KeyValueDetails/KeyValue.vue";
import { niceNumber } from "../../../utility/helpers";
import FormControl from "../../global/FormControl.vue";
import FormErrors from "../../global/FormErrors.vue";
import SuperAdminUserLink from "../../global/SuperAdminUserLink.vue";
import BillingAccount from "../shared/BillingAccount.vue";

const props = defineProps<{
  agencyForm: types.Form<types.Agency>;
}>();

const emit = defineEmits<{
  (event: "close"): void;
  (event: "update", agency: types.Agency): void;
}>();

const agency = ref<types.AgencyDetails>();

const { saveStatus, errors, api } = useModelApi<types.Agency>();

const update = async () => {
  const url = `dashboard/super_admin/api/agencies/${props.agencyForm.id}.json`;
  const method = "put";
  const data = {
    agency: props.agencyForm,
  };

  const responseData = await api({ url, method, data });

  if (responseData) {
    emit("update", responseData.agency);
    fetchAgency();
  }
};

const { fetchStatus, fetchApi } = useFetchApi();

const fetchAgency = async () => {
  const url = `dashboard/super_admin/api/agencies/${props.agencyForm.id}.json`;
  const responseData = await fetchApi({ url });

  if (responseData) {
    agency.value = responseData.agency;
  }
};

watch(() => props.agencyForm.id, fetchAgency, { immediate: true });

const {
  api: userApi,
  errors: userErrors,
  saveStatus: userSaveStatus,
} = useModelApi<schema.User>();
const userForm = ref<types.Form<schema.User>>({ email: "" });

const createUser = async () => {
  const url = `dashboard/super_admin/api/agencies/${props.agencyForm.id}/users.json`;
  const method = "post";
  const data = { user: userForm.value };

  const responseData = await userApi({ url, method, data });

  if (responseData) {
    const user = responseData.user;
    agency.value?.users.push(user);
    userForm.value = { email: "" };
    fetchAgency();
  }
};

const users = computed(() => {
  if (!agency.value) return [];

  return orderedByTimeField(agency.value.users, "lastActiveAt");
});

const agencyAdminLogs = computed(() => {
  if (!agency.value) return [];

  return orderedByTimeField(agency.value.agencyAdminLogs, "createdAt");
});

const updateBillingAccount = (billingAccount: types.BillingAccount) => {
  if (!agency.value) return;

  agency.value.billingAccount = billingAccount;
};
</script>
